import React, { useState } from 'react';
import styled from 'styled-components/macro';

import IconPen from '../icons/Pen';
import { palette } from '../../defines/styles';

const Button = styled.button`
    width: 20px;
    height: 20px;
    padding; 0;
    border: 0;
    background: transparent;

    ${props => props.disabled && (`
        svg {
            path:first-child {
                fill: ${palette.gray.light};
            }
            path:last-child {
                fill: ${palette.point.gray};
            }
        }
    `)}
`;

const PenButton = props => {
    const [hover, setHover] = useState(0);
    return (
        <Button
            type="button"
            onClick={e => props.onClick && props.onClick(e)}
            disabled={props.disabled}
            onMouseOver={() => setHover(1)} onMouseOut={() => setHover(0)}
            title={props.title}
        >
            <IconPen width="10" height="10" hover={hover} />
        </Button>
    );
};

export default PenButton;