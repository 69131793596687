import React from 'react';

const TextSquare = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '13'} height={props.height || '13'} viewBox="0 0 13 13">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#3C3C3B'}>
                <path d="M11 0c1.105 0 2 .895 2 2v9c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0h9zm-.636 2H2.636C2.285 2 2 2.285 2 2.636v1.56c0 .35.285.636.636.636h.294c.35-.003.63-.287.63-.637V3.56h2.161V9.44h-.494c-.35 0-.633.281-.636.63v.293c0 .351.285.636.636.636h2.546c.169 0 .33-.067.45-.186.12-.12.186-.282.186-.45v-.292c-.002-.35-.287-.632-.636-.63h-.494V3.558h2.164v.636c0 .349.28.633.629.637h.292c.351 0 .636-.285.636-.637V2.636c0-.351-.285-.636-.636-.636z" transform="translate(-272 -665) translate(272 665)" />
            </g>
        </g>
    </svg>
);

export default TextSquare;