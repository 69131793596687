import React from 'react';

const Pen = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '13'} height={props.height || '13'} viewBox="0 0 13 13">
        <g fill="none" fillRule="evenodd" transform="scale(1.3 1.3)">
            <path fill={props.hover === 1 ? '#90cdcb' : '#3C3C3B'} d="M5.933.294L.947 5.307c-.134.135-.227.306-.268.492l-.656 3c-.072.33.029.673.269.911.24.239.585.34.916.267l3.016-.651c.189-.041.362-.136.498-.273l4.99-5.018c.385-.387.384-1.012-.004-1.397L7.347.29C7.159.104 6.904 0 6.639 0c-.265 0-.52.107-.706.294z" transform="translate(-621 -185) translate(621 185)" />
            <path fill={props.hover === 1 ? '#3c3c3b' : '#00D7D0'} d="M1.448 8.552c-.28-.28-.397-.682-.313-1.068l.094-.432c.069-.317.382-.518.699-.45.11.025.21.08.29.16L3.24 7.783c.23.229.23.6 0 .83-.08.08-.18.135-.29.158l-.433.094c-.386.084-.788-.035-1.068-.314z" transform="translate(-621 -185) translate(621 185)" />
        </g>
    </svg>
);

export default Pen;