import React from 'react';

const VolumeOff = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '11'} height={props.height || '13'} viewBox="0 0 11 13">
        <g fill="none" fillRule="evenodd" transform="scale(1.1 1.1)">
            <g fill={props.color || '#444A58'} fillRule="nonzero">
                <path d="M.457 6.6V5.4c0-.994.783-1.8 1.75-1.8h.151c.553 0 1.102-.097 1.623-.289L7.245.434C7.562.154 7.966 0 8.385 0c.608 0 1.102.508 1.102 1.134v9.732c0 .626-.494 1.134-1.103 1.134-.418 0-.822-.154-1.139-.434L3.981 8.69c-.52-.192-1.07-.29-1.623-.289h-.151c-.967 0-1.75-.806-1.75-1.8z" transform="translate(-585 -290) translate(584.64 290)" />
            </g>
        </g>
    </svg>
);

export default VolumeOff;