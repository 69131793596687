/* eslint-disable no-undef */
import TagManager from 'react-gtm-module';
import { GTM_ID } from '@mirinae/defines/keys';
import { browser, os } from '@mirinae/js-utils/utils/userAgent';

// GA3 event recording - NO-OP -  deprecated - GA3 no  longer live, need to remove or recodew all calls to Evet() throughout the codebase
export const Event = (category, action, label) => {
};

let isFlutterInAppWebViewReady = false;
window.addEventListener('flutterInAppWebViewPlatformReady', () => {
    isFlutterInAppWebViewReady = true;
});
//  send GTM Event to webview flutter. using javascript channel
// migrate to new flutter library
const sendEvent = (command, name, parameters) => {
    if (!command || !name) {
        return;
    }

    const message = {
        command,
        name,
        parameters: { ...parameters },
    };

    if (window.GtmChannel) {
        window.GtmChannel.postMessage(JSON.stringify(message));
    } else if (isFlutterInAppWebViewReady || window.inAppView) {
        window.flutter_inappwebview.callHandler('GtmChannel', message);
    }
};

// GA4 + GTM instrumnentation API
const GTM = {
    initialize: () => {
        // initialize GTM for mirinae.io web (https://tagmanager.google.com/?authuser=3#/container/accounts/6005266631/containers/55743488/workspaces/2?orgId=CjScY1C0RbKXrfCsf6D_wQ)
        TagManager.initialize({
            gtmId: GTM_ID,
        });
    },

    pageView: (title, props = {} , app) => {
        if (window.GtmChannel || window.flutter_inappwebview) {
            sendEvent('logEvent', 'page_view', { title, property: 'app', app, ...props });
            return;
        }
        
        window.dataLayer.push({
            event: undefined,
            action: undefined,
            page: undefined,
        });
        window.dataLayer.push({
            event: 'page_view',
            page: {
                title,
                app,
                property: browser.isMirinae ? 'app' : 'web',
                os: os.name,
                ...props,
            },
        });
        console.log('GA4 pageView', title, app, props);
    },

    userAction: (code, props = {}, app) => {
        const actionCode = code.replace(/-/g, '_'); // firebase prohibits '-' in event names, make all reporting consistently snake-case
        if (window.GtmChannel || window.flutter_inappwebview) {
            sendEvent('logEvent', `explorer_${actionCode}`, { action: actionCode, app, property: 'app', ...props });
            return;
        } 
        window.dataLayer.push({ event: undefined, action: undefined });
        window.dataLayer.push({
            event: 'explorer_user_action', // mapping to explorer.${code} done in GTM
            action: {
                code: actionCode,
                app,
                property: browser.isMirinae ? 'app' : 'web',
                os: os.name,
                ...props,
            },
        });
        console.log('GA4 user action', code, app, props);
        // todo: implement this call using the new action codes, updating tut system to match:  registerUserAction(action);
    },

    appEvent: (code, props = {}, app) => {
        const eventCode = code.replace(/-/g, '_'); // firebase prohibits '-' in event names, make all reporting consistently snake-case
        if (window.GtmChannel || window.flutter_inappwebview) {
            sendEvent('logEvent', `app_${eventCode}`, { details: code, property: 'app', app, ...props });
            return;
        } 
        window.dataLayer.push({ event: undefined, details: undefined });
        window.dataLayer.push({
            event: 'app_event',
            details: {
                code: eventCode,
                app,
                property: browser.isMirinae ? 'app' : 'web',
                os: os.name,
                ...props,
            },
        });
        console.log('GA4 app event', code, app, props);
    },
};

export default GTM;

// manual dataLayer reset:
// window.google_tag_manager[{{Container ID}}].dataLayer.reset();
