const keywords = [ // todo: this should be factored across apps
    'action verb',
    'adjectival phrase',
    'adjective',
    'adjective-forming',
    'adverb',
    'adverb-forming',
    'adverbial phrase',
    'affix',
    'auxiliary verb',
    'batchim',
    'bound noun',
    'clause',
    'clause connector',
    'complement',
    'complement phrase',
    'compressed form',
    'conjugation',
    'connecting particle',
    'connecting',
    'connector',
    'contribution line',
    'copula',
    'counting words',
    'derived adjective',
    'derived adverb',
    'derived verb',
    'descriptive verb',
    'determiner',
    'dictionary form',
    'direct quotation',
    'first person',
    'formal polite style',
    'formal statement',
    'formal style',
    'future tense',
    'future-progressive tense',
    'grammar pattern',
    'grammar role',
    'hada-verb',
    'hanja',
    'hanja root',
    'honorific',
    'humilific',
    'idiom',
    'idiomatic',
    'idiomatic phrase',
    'indirect quotation',
    'informal polite style',
    'informal style',
    'interjection',
    'intimate style',
    'irregular conjugation',
    'irregular verb',
    'korean numbers',
    'location marker',
    'location particle',
    'main clause',
    'marker',
    'modified verb',
    'modifier',
    'morpheme',
    'morphological analysis',
    'native numbers',
    'negative copula',
    'negative form',
    'neologism',
    'noun',
    'noun phrase',
    'object',
    'object marker',
    'object particle',
    'object phrase',
    'parse tree',
    'part of speech',
    'particle',
    'past tense',
    'past-perfect tense',
    'past-progressive tense',
    // 'phrase',
    'phrase structure',
    'plain style',
    'predicate',
    'present tense',
    'present-progressive tense',
    'progressive tense',
    'pronoun',
    'root',
    'second person',
    'sentence-ending',
    'short form',
    'short quote',
    'sino-korean numbers',
    'stem',
    'subject',
    'subject marker',
    'subject particle',
    'subject phrase',
    'subordinate clause',
    'suffix',
    'tense',
    'tense form',
    'third person',
    'topic',
    'topic article',
    'topic marker',
    'topic phrase',
    'verb',
    'verb phrase',
    'verb stem',
    'verb suffix',
    'auxiliary',
    '1st person',
    '2nd person',
    '3rd person',
    '받침',
    '하다',
    '하다-verb',
];

export const keywordsByLetter = {};
keywords.sort((a, b) => b.length - a.length).forEach(kw => {
    if (!keywordsByLetter[kw[0]])
        keywordsByLetter[kw[0]] = [kw];
    else
        keywordsByLetter[kw[0]].push(kw);
});