export const palette = {
    // Mirinae 1.0
    main: {
        main: '#87CBC8',
        darken: {
            1: '#48A9A6',
            2: '#308D8A',
        },
        highlight: '#00D7D0',
        deset: {
            1: '#6E9292',
            2: '#8FACAC',
            3: '#AFC0C0',
            4: '#D1D9D9',
        },
        lighten: {
            1: '#A5D6D3',
            2: '#C5EAE7',
            3: '#E0FBFA',
            4: '#F1FFFF',
        },
    },
    pro: {
        default: '#5866A4',
        darken: '#000F60',
        desat: {
            1: '#8A94BF',
        },
        lighten: {
            1: '#E0E6FF',
            2: '#EFF2FF',
            3: '#F8F9FF',
        },
    },
    primary: {
        black: '#3C3C3B',
        white: '#FFF',
    },
    warning: {
        default: '#CE3B0C',
        lighten: {
            1: '#F7754B',
        },
        darken: {
            1: '#A83916',
        },
    },
    gray: {
        // Mirinae 1.0
        1: '#525252',
        2: '#626262',
        3: '#7A7A7A',
        4: '#9D9D9D',
        5: '#C4C4C4',
        6: '#DFDFDF',
        7: '#F4F4F4',
        8: '#FBFBFB',

        // deprecated
        lighter: 'rgba(150,150,150,.5)',
        light: '#dfdfdf',
        middle: '#f7f7f7',
        border: '#c8c8c8',
        dark: '#979797',
    },
    body: '#ffffff', // '#f6fbfb',

    // deprecated
    point: {
        blue: '#377efa',
        green: '#71ca81',
        orange: '#ff8826',
        red: '#f63a3a',
        black: '#3c3c3b',
        gray: '#8b8b8b',
    },
    green: {
        light: '#e0fbfa',
        middle: '#48a9a6',
        dark: '#53aca9',
    },
    red: {
        light: '#ffb2b2',
        middle: '#e95e5e',
    },
    sns: {
        youtube: '#cc0014',
    },
    white: '#fff',
    placeholder: '#b9b9b9',
    shadow: '#9fbcba',
    phraseType: {
        'Subject Phrase': { tree: { dark: '#59BB5D', light: '#E6F5E7' }, box: { dark: '#C5E7C6', light: '#E6F5E7', text: '#59BB5D' } }, // 주어
        'Object Phrase': { tree: { dark: '#377efa', light: '#EBF3FF' }, box: { dark: '#C3D9FE', light: '#EBF3FF', text: '#377EFA' } }, // 목적어
        'Noun Phrase': { tree: { dark: '#377efa', light: '#EBF3FF' }, box: { dark: '#C3D9FE', light: '#EBF3FF', text: '#377EFA' } }, // 목적어
        'Complement Phrase': { tree: { dark: '#BB772A', light: '#F9F2EA' }, box: { dark: '#FCECBE', light: '#FEF7E3', text: '#D1A041' } }, // 보어
        'Verb Phrase': { tree: { dark: '#E95E5E', light: '#FDEFEF' }, box: { dark: '#F9CFCF', light: '#FDEFEF', text: '#E95E5E' } }, // 서술어
        'Verb And Auxiliary': { tree: { dark: '#7A7A7A', light: '#F2F2F2' }, box: { dark: '#7A7A7A', light: '#F2F2F2', text: '#7A7A7A' } }, // 서술어
        'Adjectival Phrase': { tree: { dark: '#BB772A', light: '#F9F2EA' }, box: { dark: '#FCECBE', light: '#FEF7E3', text: '#D1A041' } }, // 관형어
        'Adverbial Phrase': { tree: { dark: '#BB772A', light: '#F9F2EA' }, box: { dark: '#FCECBE', light: '#FEF7E3', text: '#D1A041' } }, // 부사어
        'Connector': { tree: { dark: '#BB772A', light: '#F9F2EA' }, box: { dark: '#FCECBE', light: '#FEF7E3', text: '#D1A041' } }, // 부사어
        // 독립어 #8b8b8b
        'Topic Phrase': { tree: { dark: '#934C75', light: '#F7F1F4' }, box: { dark: '#E9CAF9', light: '#F8EEFD', text: '#B54CE8' } }, // 주제어
        'Clause': { tree: { dark: '#934C75', light: '#F7F1F4' }, box: { dark: '#7A7A7A', text: '#7A7A7A' } }, // 절
        'Main Clause': { tree: { dark: '#934C75', light: '#F7F1F4' }, box: { dark: '#934C75', text: '#934C75' } }, // 절
        'Subordinate Clause': { tree: { dark: '#7A7A7A', light: '#F2F2F2' }, box: { dark: '#7A7A7A', text: '#7A7A7A' } }, // 절
        'Idiom': { tree: { dark: '#934C75', light: '#F7F1F4' }, box: { dark: '#6366B9', text: '#6366B9' } }, // 관용어
         unknown: { tree: { dark: '#7A7A7A', light: '#F2F2F2' }, box: { dark: '#D8D8D8', light: '#F2F2F2', text: '#7A7A7A' } },
    },
    treeNode: {

    },
    analysis: {
        phoneme: '#3c3c3b',
        phonemeHighlighted: '#48a9a6',
        phonemeSelected: '#ff8826',
        word: '#48a9a6',
        posLabelRole: '#9D9D9D',
        posLabelRoleHover: '#525252',
        treelabel: '#ad47de',
        posLabelTranslation: '#FF8826', // '#ba6621',
        posLabelMeaning: '#b54ce8',
        boxMeaning: '#7A7A7A',
    },
    reference: {
        bannerPattern: '#53aca9',
        bannerLabel: '#8c8c8c',
        description: '#8c8c8c',
        patternType: '#846892',
        xxx: 'rgb(186, 102, 33)',
        yyy: 'rgb(186, 102, 33)',
    },
};

export const zIndex = {
    float: 10,
    header: 350,
    hint: 260,
    tutorial: 390,
    threadsBoard: 300,
    feedbackPopup: 400,
    keyboard: 500,
    cookieBanner: 1000,
};

export const fontkoKR = '\'Noto Sans KR\', sans-serif';
export const fontDefaultAlt = '\'Montserrat Alternates\', sans-serif';
let _fontDefault = '';
switch (navigator.language) {
    case 'ja':
        _fontDefault = '\'Noto Sans JP\', sans-serif';
        break;
    case 'vi':
        _fontDefault = 'verdana';
        break;
    default:
        _fontDefault = 'Montserrat, sans-serif';
        break;
}
export const fontDefault = _fontDefault;

export const size = {
    wrap: '1200px',
    width: '1160px', // 1200px minus scroll width
    height: '780px',
    desktopWidth: '740px',
    mobileWidth: '280px',
    header: '68px',
    bottom: '105px',
};

export const breakPoint = {
    lg: '1200px',
    md: '1024px',
    sm: '740px',
    xs: '320px',
    common: {
        lg: '900px',
        md: '480px',
        sm: '320px',
    },
};

export const classes = {
    mobile: 'isMobile',
    desktop: 'isDesktop',
    parseTree: 'has-parseTree',
};

// defines the prime layout parameters & font specs for the main analysis display
export const analysisStyle = {
    koreanFont: fontkoKR,
    englishFont: fontDefault,
    word: {
        fontSize: 19,
        fontWeight: 400,
    },
    phoneme: {
        fontSize: 25,
        fontWeight: 700,
    },
    posLabelRole: {
        fontSize: 13,
        fontWeight: 500,
    },
    posLabelMeaning: {
        fontSize: 14,
        fontWeight: 500,
    },
    posLabelTranslation: {
        fontSize: 14,
        fontWeight: 500,
    },
    treeLabel: {
        fontSize: 13,
        fontWeight: 500,
    },
    //
    grammarDef: {
        tabLabel: {
            fontSize: 13,
            fontWeight: 500,
        },
    },
    nodeDiameter: 10,
    linkLineStrokeWidth: 1.0,

    displayOptionsInit: {
        layout: {
            originX: 20,
            originY: 40,
            morphemeGap: 15,
            wordGap: 15,
            lineGap: 19,
            posGap: 13,
            fontHeight: {
                // word: 28, morpheme: 37, posLabel: 17, treeLabel: 18, idiom: 16,
                word: 17, morpheme: 22, posLabel: 10, treeLabel: 10, idiom: 25,
            },
            fontDescent: {
                word: 3, morpheme: 5, posLabel: 3, treeLabel: 4, idiom: 4,
            },
            bracketGap: 2,
            nodeLabelGap: 16,
            bracketHeight: 15,
            phraseBoxGap: 23,
            clauseBoxGap: 43,
            overallScale: { small: 0.75, normal: 1.0, large: 1.25 },
        },
        phraseDisplayMode: 'tree', // 'tree' or 'box' or ''
        showTranslations: true,
        showParseTree: true,
        compactParseTree: false,
        showPartsOfSpeech: true,
        showIdioms: true,
        scaleToFitWidth: null,
        autoScaleToFit: null,
        fontSize: 'normal',
        showFullParseTree: false,
        showDebugging: false,
    },
};