// API URL path definitions for the NLP back-end services
//         Copyright © 2020 Mirinae Corp.

// NLP service
export const nlpPaths = {
    // these now pass through the front-end-server to ensure user-authenication, hence the /api prefix
    analyze:        '/api/nlp/analyze',
    analyzeNext:    '/api/nlp/analyze/next',
    reanalyze:      '/api/nlp/reanalyze',
    bookmark:       '/api/nlp/bookmark',
    listHistory:    '/api/nlp/hist/list',
    // grammar & reference library service
    setWordMeaning: '/api/nlp/setwordmeaning',
    setParseTree:   '/api/nlp/setparsetree',
    getPattern:     '/api/nlp/pattern/get',
    allExtRefs:     '/api/nlp/extref/getall',
    // related discussions & lessons
    getRelatedLessonsAndDisccussions: '/api/nlp/patterns/related',
};
