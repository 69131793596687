import React from 'react';

const CopySquare = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '13'} height={props.height || '13'} viewBox="0 0 13 13">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#3C3C3B'}>
                <path d="M11 0c1.105 0 2 .895 2 2v9c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0h9zM9.875 2h-4.5c-.298 0-.585.118-.796.33-.211.21-.33.497-.33.795V4.25H3.125c-.298 0-.585.118-.796.33-.21.21-.33.497-.329.795v4.5C2 10.496 2.504 11 3.125 11h4.5c.298 0 .584-.119.795-.33.211-.21.33-.497.33-.795V8.75h1.125c.621 0 1.125-.504 1.125-1.125v-4.5C11 2.504 10.496 2 9.875 2zm0 1.125v4.5H8.749v-2.25c0-.298-.118-.584-.329-.795-.21-.211-.497-.33-.795-.33h-2.25V3.125h4.5z" transform="translate(-304 -665) translate(304 665)" />
            </g>
        </g>
    </svg>
);

export default CopySquare;