import React from 'react';

const PaperPlaneSquare = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '13'} height={props.height || '13'} viewBox="0 0 13 13">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color ? props.color : '#3C3C3B'}>
                <path d="M11 0c1.105 0 2 .895 2 2v9c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0h9zm-.385 2.02L2.261 5.137c-.16.06-.264.21-.261.376.003.166.113.312.275.366l1.855.63c.165.057.347.039.497-.05l4.053-2.37c.057-.028.125-.013.165.035.039.048.037.116-.004.163l-3.04 2.996c-.11.093-.173.227-.174.368l-.014 3.092c.007.125.1.228.226.253.126.024.253-.036.31-.148L7.266 8.65c.137-.213.424-.28.647-.153l1.982 1.06c.129.07.285.07.414 0 .129-.068.212-.196.222-.338L11 2.27c0-.09-.046-.174-.123-.225-.078-.05-.176-.059-.262-.024z" transform="translate(-368 -665) translate(368 665)" />
            </g>
        </g>
    </svg>
);

export default PaperPlaneSquare;