import React from 'react';

const CommentSquare = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '13'} height={props.height || '13'} viewBox="0 0 13 13">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#3C3C3B'}>
                <path d="M11 0c1.105 0 2 .895 2 2v9c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V2C0 .895.895 0 2 0h9zM5.922 2.056c-1.518.249-2.797 1.295-3.373 2.757-.576 1.462-.364 3.127.56 4.387l-1 1.101c-.11.121-.14.298-.075.45.064.15.21.249.371.248h4.032c2.392.038 4.395-1.852 4.559-4.301.057-1.351-.48-2.656-1.464-3.556-.983-.9-2.309-1.298-3.61-1.086z" transform="translate(-336 -665) translate(336 665)" />
            </g>
        </g>
    </svg>
);

export default CommentSquare;