import React from 'react';

const Anchor = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || '13'} height={props.height || '13'} viewBox="0 0 13 13">
        <g fill="none" fillRule="evenodd">
            <g fill={props.color || '#3C3C3B'} fillRule="nonzero">
                <g>
                    <path d="M6.521 9.627l-.854.855c-.88.794-2.229.76-3.067-.079-.84-.838-.875-2.186-.082-3.067l.854-.855c.366-.364.366-.956.001-1.32-.364-.366-.956-.366-1.321-.002l-.853.854c-1.599 1.599-1.599 4.19 0 5.788 1.598 1.599 4.19 1.599 5.787 0l.855-.853c.364-.364.365-.955 0-1.32-.364-.365-.955-.365-1.32 0z" transform="translate(-205 -356) translate(205 354) translate(0 2)" />
                    <path d="M7.16 4.52L4.52 7.16c-.364.365-.364.955 0 1.32.365.364.955.364 1.32 0l2.64-2.64c.364-.365.364-.955 0-1.32-.365-.364-.956-.364-1.32 0z" transform="translate(-205 -356) translate(205 354) translate(0 2)" />
                    <path d="M11.801 1.199C11.034.43 9.993 0 8.907 0 7.822 0 6.781.431 6.013 1.199l-.854.853c-.365.365-.365.956 0 1.32.364.365.955.365 1.32 0l.854-.854c.88-.794 2.229-.76 3.067.079.839.838.875 2.186.082 3.067l-.855.855c-.365.364-.365.956 0 1.321.364.365.956.366 1.32 0l.854-.853C12.57 6.219 13 5.178 13 4.093c0-1.086-.431-2.127-1.199-2.894z" transform="translate(-205 -356) translate(205 354) translate(0 2)" />
                </g>
            </g>
        </g>
    </svg>
);

export default Anchor;