/* TutorialContext, used by the tut/help system to track tutorial states
 */
import React, { createContext, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';

import { UserAgent } from '@mirinae/js-utils';

import GTM from '@mirinae/shared/apis/GoogleAnalytics';

export const TutorialContext = createContext({});

// initializing tutorial state
const tutorialStateInit = {
};


// Establishes TutorialContext via a custom use-hook.
//  returns as the context value and object containing the selection state and several useful updater functions
export function useTutorialContext() {
    const [tutorialState, setTutorialState] = useState(tutorialStateInit);
    const [cookie, setCookie] = useCookies(['welcomed']);

    const updateTutorialState = (update) => {
        setTutorialState((prev) => ({ ...prev, ...update }));
    };

    const notifyTutorialElementsPresent = () => {};

    const getLandingState = (urlParams) => {
        // determine any tut notes to show for current page, managed by 'welcomed' cookie and localStorage['landingNotesShown']
        const delayLandingSeq = Boolean(urlParams.ui && !cookie.welcomed);
        const landing = !cookie.welcomed && !delayLandingSeq;
        return landing;
    };

    const clear = () => {
        setTutorialState(tutorialStateInit);
    };

    // TutorialContext value
    return {
        ...tutorialState,
        update: updateTutorialState,
        notifyTutorialElementsPresent,
        getLandingState,
        clear,
    };
}
